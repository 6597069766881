import { Options } from 'vue-class-component';

import config from '@/config/config';
import PageBaseView from '@/pages/PageBaseView';
import * as CarGroupService from '@/services/car-group.service';
import { ICarGroup } from '@/domain/car-group.model';

@Options({
  name: 'car-group-view',
  props: {
    id: String,
  },
})
export default class CarGroupView extends PageBaseView {
  public row: ICarGroup = {
    id_city: 0,
    name: '',
    description: '',
    city_description: '',
    total_cars: 0,
    enabled: false,
  };

  public mounted(): void {
    this.url = 'car-group';
    this.module = config.modules.carGroup;

    CarGroupService.get(this.id)
      .then((res: ICarGroup) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }
}
