import { Options, Vue } from 'vue-class-component';

import config from '@/config/config';
import PageBaseView from '@/pages/PageBaseView';
import * as TypeMediaService from '@/services/type-media.service';
import { ITypeMedia } from '@/domain/type-media.model';

@Options({
  name: 'type-media-view',
  props: {
    id: String,
  },
})
export default class TypeMediaView extends PageBaseView {
  public row: ITypeMedia = {
    tag: '',
    time: 0,
    max_file_size: '',
    type_description: '',
    description: '',
    enabled: false,
    document_type: 0,
  };

  public mounted(): void {
    this.url = 'type-media';
    this.module = config.modules.typeMedia;

    TypeMediaService.get(this.id)
      .then((res: ITypeMedia) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }
}
