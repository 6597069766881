import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';

import { onlyNumbers } from '@/helpers/numbers';
import * as TypeMediaService from '@/services/type-media.service';
import * as DocumentTypeService from '@/services/document-type.service';
import { ITypeMedia } from '@/domain/type-media.model';
import { IDocumentType } from '@/domain/document-type.model';
import { IList } from '@/domain/list.model';

@Options({
  name: 'type-media-edit',
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class TypeMediaEdit extends Vue {
  public id: string;
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public documentTypes: IList[] = reactive([]);
  public row: ITypeMedia = {
    tag: '',
    description: '',
    time: 0,
    max_file_size: '',
    max_file_size_type: 'MB',
    type_description: '',
    enabled: false,
    document_type: 0,
  };

  public created(): void {
    DocumentTypeService.listActive()
      .then((res: IDocumentType[]) => {
          this.documentTypes = res.map((i) => {
          return { id: i.id, description: i.description };
        });
      });
  }

  public mounted(): void {
    TypeMediaService.get(this.id)
      .then((res: ITypeMedia) => {
        if (res && res.id) {
          this.row = res;
          this.row.max_file_size_type = res.max_file_size?.substring(res.max_file_size?.length - 2);
          this.row.max_file_size = onlyNumbers(res.max_file_size);
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public goBack() {
    this.$router.push('/type-media');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.tag) {
      this.errors.tag = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.time) {
      this.errors.time = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.max_file_size) {
      this.errors.max_file_size = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.document_type) {
      this.errors.document_type = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.description) {
      this.errors.description = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      TypeMediaService.update({
        id: parseInt(this.id),
        tag: this.row.tag,
        description: this.row.description,
        time: this.row.time,
        max_file_size: this.row.max_file_size + this.row.max_file_size_type,
        document_type: this.row.document_type,
        enabled: this.row.enabled,
      })
        .then(() => {
          this.$router.push('/type-media');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
