import { reactive } from 'vue';
import { Options } from 'vue-class-component';
import masks from 'magic-masks';

import config from '@/config/config';
import PageBaseList from '@/pages/PageBaseList';
import TableLite from '@/components/table/TableLiteTs.vue';
import * as CompanyService from '@/services/company.service';
import { IPaginateVueTable } from '@/interfaces/IPaginateVueTable';
import { ICompany } from '@/domain/company.model';

@Options({
  name: 'company-list',
  components: {
    TableLite,
  },
})
export default class CompanyList extends PageBaseList {
  public table = TABLE;
  public onlyEnabled = false;

  public mounted(): void {
    this.url = 'company';
    this.module = config.modules.company;
    this.table.rows = [];

    this.doSearch(0, this.per_page, 'id', 'asc');
  }

  public doSearch(
    offset?: number,
    limit?: number,
    order?: string,
    sort?: string,
    filter?: string,
    onlyEnabled?: boolean,
  ) {
    this.table.isLoading = true;
    this.table.isReSearch = offset == undefined ? true : false;

    if (offset >= 10 || limit >= this.per_page) {
      limit = this.per_page;
    }

    CompanyService.list(offset, limit, order, sort, filter, onlyEnabled).then((res: IPaginateVueTable<ICompany>) => {
      this.table.rows = res?.data;
      this.table.totalRecordCount = res?.total;
      this.table.sortable.order = order;
      this.table.sortable.sort = sort;

      this.table.rows.forEach((i: any) => {
        i.cnpj = masks.cnpj(i.cnpj);
      });
    });
  }

  public reload() {
    this.doSearch(0, this.per_page, 'id', 'asc', this.filter, this.onlyEnabled);
  }

  public doFilter() {
    if (this.filter.length == 0 || this.filter.length >= 2) {
      this.doSearch(0, this.per_page, 'id', 'asc', this.filter, this.onlyEnabled);
    }
  }
}

export const TABLE = reactive({
  isLoading: false,
  isReSearch: false,
  columns: [
    {
      label: 'Id',
      field: 'id',
      sortable: true,
      isKey: true,
    },
    {
      label: 'CNPJ',
      field: 'cnpj',
      sortable: true,
    },
    {
      label: 'Razão social',
      field: 'company',
      sortable: true,
      columnStyles: 'text-align: left',
    },
    {
      label: 'Nome fantasia',
      field: 'trade_name',
      sortable: true,
      columnStyles: 'text-align: left',
    },
    {
      label: 'Tipo',
      field: 'type_description',
      sortable: true,
    },
    {
      label: 'Situação',
      field: 'enabled',
      sortable: true,
    },
    {
      field: 'actions',
    },
  ],
  rows: [{}],
  totalRecordCount: 0,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});
