import { LoginModule } from '@/domain/login.model';
import { IUser } from '@/interfaces/user';
import router from '@/router/index';

export default {
  login: (context: any, payload: string): void => {
    context.commit('setToken', payload);
    router.replace('/');
  },
  getUser: (context: any, payload: IUser): void => {
    context.commit('setUser', payload);
  },
  getExpiresIn: (context: any, payload: number): void => {
    context.commit('setExpiresIn', payload);
  },
  getModules: (context: any, payload: LoginModule[]): void => {
    context.commit('setModules', payload);
  },
  logout: (context: any): void => {
    context.commit('setToken', null);
    context.commit('setExpiresIn', null);
    context.commit('setUser', null);
    context.commit('setModules', null);

    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('modules');

    router.replace('/login');
  },
};
