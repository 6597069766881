import { Options, Vue } from 'vue-class-component';

import config from '@/config/config';
import PageBaseView from '@/pages/PageBaseView';
import * as EconomicSectorService from '@/services/economic-sector.service';
import { IEconomicSector } from '@/domain/economic-sector.model';

@Options({
  name: 'economic-sector-view',
  props: {
    id: String,
  },
})
export default class EconomicSectorView extends PageBaseView {
  public row: IEconomicSector = {
    description: '',
    enabled: false,
  };

  public mounted(): void {
    this.url = 'economic-sector';
    this.module = config.modules.economicSector;

    EconomicSectorService.get(this.id)
      .then((res: IEconomicSector) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }
}
