import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';
import { isEmail } from 'node-simple-validator';
import masks from 'magic-masks';

import { onlyNumbers } from '@/helpers/numbers';
import * as ContactService from '@/services/contact.service';
import * as ContactTypeService from '@/services/contact-type.service';
import { IContact } from '@/domain/contact.model';
import { IList } from '@/domain/list.model';
import { IContactType } from '@/domain/contact-type.model';

@Options({
  name: 'contact-edit',
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class ContactEdit extends Vue {
  public id: string;
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public contactTypes: IList[] = reactive([]);
  public row: IContact = {
    id_company: 0,
    contact_type: 0,
    name: '',
    email: '',
    phone1: '',
    phone2: '',
    description: '',
    enabled: false,
    authorization_email: false,
    authorization_sms: false,
    authorization_whatsapp: false,
  };

  public created(): void {
    ContactTypeService.listActive()
      .then((res: IContactType[]) => {
        this.contactTypes = res.map((i) => {
          return { id: i.id, description: i.description };
        });
      });
  }

  public mounted(): void {
    ContactService.get(this.id)
      .then((res: IContact) => {
        if (res && res.id) {
          this.row = res;

          this.row.phone1 = this.row.phone1 ? masks.cellphoneOrPhone(this.row.phone1) : '';
          this.row.phone2 = this.row.phone2 ? masks.cellphoneOrPhone(this.row.phone2) : '';
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public goBack() {
    this.$router.push('/company/' + this.row.id_company);
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.name) {
      this.errors.name = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.contact_type) {
      this.errors.contact_type = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.email) {
      this.errors.email = 'Este campo deve ser preenchido.';
      this.hasError = true;
    } else if (!isEmail(this.row.email)) {
      this.errors.email = 'E-mail inválido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      ContactService.update({
        id: parseInt(this.id),
        contact_type: this.row.contact_type,
        name: this.row.name,
        email: this.row.email,
        phone1: this.row.phone1 ? onlyNumbers(this.row.phone1) : undefined,
        phone2: this.row.phone2 ? onlyNumbers(this.row.phone2) : undefined,
        description: this.row.description,
        authorization_email: this.row.authorization_email,
        authorization_sms: this.row.authorization_sms,
        authorization_whatsapp: this.row.authorization_whatsapp,
        enabled: this.row.enabled,
      })
        .then(() => {
          this.$router.push('/company/' + this.row.id_company);
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
