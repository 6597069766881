import axios from 'axios';

import config from '@/config/config';
import { headers } from './auth.headers';
import { formatQueryString } from '@/helpers/query-string';
import { IPaginateVueTable } from '@/interfaces/IPaginateVueTable';
import { IContact } from '@/domain/contact.model';

const url_api_base = config.url_api_cliente;

export const insert = (data: IContact): Promise<IContact> => {
  return axios.post(`${url_api_base}/contact`, data, headers());
};

export const update = (data: IContact): Promise<IContact> => {
  return axios.patch(`${url_api_base}/contact/${data.id}`, data, headers());
};

export const get = (id: string): Promise<IContact> => {
  const url = `${url_api_base}/contact/${id}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const list = (
  offset?: number,
  limit?: number,
  order?: string,
  sort?: string,
  filter?: string,
): Promise<IPaginateVueTable<IContact>> => {
  const query = formatQueryString(offset, limit, order, sort, filter);
  const url = `${url_api_base}/contact${query}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const getByCompany = (id_company: string): Promise<IContact[]> => {
  const url = `${url_api_base}/company/${id_company}/contacts`;

  return axios.get(url, headers()).then((res) => res?.data);
};
