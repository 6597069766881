import { Options } from 'vue-class-component';

import config from '@/config/config';
import PageBaseView from '@/pages/PageBaseView';
import * as CityService from '@/services/city.service';
import { ICity } from '@/domain/city.model';

@Options({
  name: 'city-view',
  props: {
    id: String,
  },
})
export default class CityView extends PageBaseView {
  public row: ICity = {
    city: '',
    state: '',
    max_order_cars: 0,
    enabled: false,
  };

  public mounted(): void {
    this.url = 'city';
    this.module = config.modules.city;

    CityService.get(this.id)
      .then((res: ICity) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }
}
