import { Options, Vue } from 'vue-class-component';

import config from '@/config/config';
import PageBaseView from '@/pages/PageBaseView';
import * as CompanyTypeService from '@/services/company-type.service';
import { ICompanyType } from '@/domain/company-type.model';

@Options({
  name: 'company-type-view',
  props: {
    id: String,
  },
})
export default class CompanyTypeView extends PageBaseView {
  public row: ICompanyType = {
    description: '',
    enabled: false,
  };

  public mounted(): void {
    this.url = 'company-type';
    this.module = config.modules.companyType;

    CompanyTypeService.get(this.id)
      .then((res: ICompanyType) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }
}
