import { Options, Vue } from 'vue-class-component';

import List from '@/pages/car-group/list/list.vue';

@Options({
  name: 'car-group',
  components: {
    'page-list': List,
  },
})
export default class CarGroup extends Vue {
  public isIndexOnly() {
    return /^[/]?car-group[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
