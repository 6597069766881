const modules = {
  dashboard: 'DASHBOARD',
  carGroup: 'CAR_GROUP',
  city: 'CITY',
  company: 'COMPANY',
  companySegment: 'COMPANY_SEGMENT',
  companyType: 'COMPANY_TYPE',
  contact: 'CONTACT',
  contactType: 'CONTACT_TYPE',
  documentType: 'DOCUMENT_TYPE',
  economicSector: 'ECONOMIC_SECTOR',
  insideSales: 'INSIDE_SALES',
  priceList: 'PRICE_LIST',
  productType: 'PRODUCT_TYPE',
  order: 'ORDER',
  orderDocument: 'ORDER_DOCUMENT',
  orderType: 'ORDER_TYPE',
  typeMedia: 'TYPE_MEDIA',
  systemAccessProfile: 'SYSTEM_ACCESS_PROFILE',
  systemUser: 'SYSTEM_USER',
  systemParam: 'SYSTEM_PARAM',
};

export default modules;
