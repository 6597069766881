import { reactive } from 'vue';
import { IUser } from '@/interfaces/user';
import { Options, Vue } from 'vue-class-component';

import config from '@/config/config';
import MenuItem from '@/components/menu-item/menu-item.vue';
import { LoginModule } from '@/domain/login.model';
import { IMenuItem } from '@/interfaces/menu';

@Options({
  name: 'app-menu-sidebar',
  components: {
    'app-menu-item': MenuItem,
  },
})
export default class MenuSidebar extends Vue {
  public menu = reactive([]);
  private modules: LoginModule[] = [];

  public mounted(): void {
    this.modules = this.$store.getters['auth/modules'];

    this.menu = this.verifyPermission(JSON.parse(JSON.stringify(config.menu)));
  }

  get user(): IUser {
    return this.$store.getters['auth/user'];
  }

  get sidebarSkin() {
    return this.$store.getters['ui/sidebarSkin'];
  }

  private verifyPermission(item: IMenuItem[]) {
    let newItem = item.filter((i) => !i.modulePermission || this.modules.find((m) => m.module === i.modulePermission && m.enabled_read));

    newItem.forEach((i) => {
      if (i.children) {
        i.children = this.verifyPermission(i.children);
      }
    });

    newItem = newItem.filter((i) => (i.path && !i.path.startsWith('#')) || (i.path?.startsWith('#') && i.children?.length > 0));

    return newItem;
  }
}
