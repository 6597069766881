import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';

import * as DocumentTyperService from '@/services/document-type.service';
import { IDocumentType } from '@/domain/document-type.model';

@Options({
  name: 'document-type-edit',
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class DocumentTyperEdit extends Vue {
  public id: string;
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public extensions = ['mp4','mpeg','avi','mkv','jpeg','jpg','png'];
  public row: IDocumentType = {
    description: '',
    extensions_enabled: [],
    enabled: false,
  };

  public mounted(): void {
    DocumentTyperService.get(this.id)
      .then((res: IDocumentType) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public goBack() {
    this.$router.push('/document-type');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.description) {
      this.errors.description = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (this.row.extensions_enabled.length == 0) {
      this.errors.extensions_enabled = 'Selecione ao menos um item.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      DocumentTyperService.update({
        id: parseInt(this.id),
        description: this.row.description,
        extensions_enabled: this.row.extensions_enabled,
        enabled: this.row.enabled,
      })
        .then(() => {
          this.$router.push('/document-type');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
