import { LoginResponse } from '@/domain/login.model';
import { IUser } from '@/interfaces/user';
import * as SystemUserService from '@/services/system-user.service';

const getError = (error: any) => {
  const message = error?.response?.data?.error || error.message || 'Failed';
  return new Error(message);
};

export const loginByAuth = async (username: string, password: string): Promise<LoginResponse> => {
  return SystemUserService.login({
    username,
    password,
  })
    .then((res) => {
      localStorage.setItem('token', res.token);
      localStorage.setItem('expiresIn', `${res.expires_in}`);
      localStorage.setItem('user', JSON.stringify(res.user));
      localStorage.setItem('modules', JSON.stringify(res.modules));

      return res;
    })
    .catch((error) => {
      throw getError(error);
    });
};

export const registerByAuth = async (email: string, password: string) => {
  try {
    // const token = await Gatekeeper.registerByAuth(email, password);
    // return token;
  } catch (error: any) {
    throw getError(error);
  }
};

export const getProfile = async () => {
  try {
    const user: IUser = JSON.parse(localStorage.getItem('user'));

    return user;
  } catch (error: any) {
    throw getError(error);
  }
};
