import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';

import * as SystemAccessProfileService from '@/services/system-access-profile.service';
import * as SystemModuleProfileService from '@/services/system-module-profile.service';
import { ISystemModuleProfile } from '@/domain/system-module-profile.model';
import { ISystemAccessProfile } from '@/domain/system-access-profile.model';

@Options({
  name: 'system-access-profile-edit',
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class SystemAccessProfileEdit extends Vue {
  public id: string;
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public systemModuleProfile: ISystemModuleProfile[] = reactive([]);
  public row: ISystemAccessProfile = {
    description: '',
    name: '',
    enabled: false,
  };

  public mounted(): void {
    SystemAccessProfileService.get(this.id)
      .then((res: ISystemAccessProfile) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });

    this.loadSystemModuleProfile();
  }

  public goBack() {
    this.$router.push('/system-access-profile');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.description) {
      this.errors.description = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public loadSystemModuleProfile() {
    SystemModuleProfileService.getModulesByProfileId(this.id)
      .then((res: ISystemModuleProfile[]) => {
        this.systemModuleProfile = res;
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public save() {
    if (this.validation()) {
      SystemAccessProfileService.update({
        id: parseInt(this.id),
        description: this.row.description,
        name: this.row.name,
        enabled: this.row.enabled,
      })
        .then(() => {
          for (const module of this.systemModuleProfile) {
            module.id_access_profile = parseInt(this.id);

            module.enabled_create = [null, undefined].includes(module.enabled_create) ? false : module.enabled_create;
            module.enabled_delete = [null, undefined].includes(module.enabled_delete) ? false : module.enabled_delete;
            module.enabled_edit = [null, undefined].includes(module.enabled_edit) ? false : module.enabled_edit;
            module.enabled_read = [null, undefined].includes(module.enabled_read) ? false : module.enabled_read;
          }

          SystemModuleProfileService.update(this.id, this.systemModuleProfile)
            .then(() => {
              this.$router.push('/system-access-profile');
            })
            .catch(() => {
              this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
            });
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
