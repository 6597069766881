import { reactive } from 'vue';
import { Options } from 'vue-class-component';
import masks from 'magic-masks';

import config from '@/config/config';
import PageBaseList from '@/pages/PageBaseList';
import TableLite from '@/components/table/TableLiteTs.vue';
import * as InsideSalesService from '@/services/inside-sales.service';
import { IPaginateVueTable } from '@/interfaces/IPaginateVueTable';
import { IInsideSales } from '@/domain/inside-sales.model';

@Options({
  name: 'inside-sales-list',
  components: {
    TableLite,
  },
})
export default class InsideSalesList extends PageBaseList {
  public table = TABLE;

  public mounted(): void {
    this.url = 'inside-sales';
    this.module = config.modules.insideSales;
    this.table.rows = [];

    this.doSearch(0, this.per_page, 'id', 'asc');
  }

  public doSearch(
    offset?: number,
    limit?: number,
    order?: string,
    sort?: string,
    filter?: string
  ) {
    this.table.isLoading = true;

    this.table.isReSearch = offset == undefined ? true : false;
    if (offset >= 10 || limit >= this.per_page) {
      limit = this.per_page;
    }

    InsideSalesService.list(offset, limit, order, sort, filter).then((res: IPaginateVueTable<IInsideSales>) => {
      this.table.rows = res?.data;
      this.table.totalRecordCount = res?.total;
      this.table.sortable.order = order;
      this.table.sortable.sort = sort;

      this.table.rows.forEach((i: any) => {
        i.cpf = masks.cpf(i.cpf);
      });
    });
  }
}

export const TABLE = reactive({
  isLoading: false,
  isReSearch: false,
  columns: [
    {
      label: 'Id',
      field: 'id',
      sortable: true,
      isKey: true,
      columnStyles: 'text-align: left;',
    },
    {
      label: 'Nome',
      field: 'name',
      sortable: true,
      columnStyles: 'text-align: left;',
    },
    {
      label: 'CPF',
      field: 'cpf',
      sortable: true,
    },
    {
      label: 'E-mail',
      field: 'email',
      sortable: true,
      columnStyles: 'text-align: left',
    },
    {
      label: 'Situação',
      field: 'enabled',
      sortable: true,
    },
    {
      field: 'actions',
    },
  ],
  rows: [{}],
  totalRecordCount: 0,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});
