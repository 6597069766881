import { Options, Vue } from 'vue-class-component';

import List from '@/pages/config/type-media/list/list.vue';

@Options({
  name: 'type-media',
  components: {
    'page-list': List,
  },
})
export default class TypeMedia extends Vue {
  public isIndexOnly() {
    return /^[/]?type-media[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
