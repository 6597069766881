import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useToast } from 'vue-toastification';

import store from '@/store/index';
import { LoginModule } from '@/domain/login.model';

import Main from '@/modules/main/main.vue';
import Login from '@/modules/login/login.vue';
import Register from '@/modules/register/register.vue';
import Dashboard from '@/pages/dashboard/dashboard.vue';
import Profile from '@/pages/config/profile/profile.vue';
import ForgotPassword from '@/modules/forgot-password/forgot-password.vue';
import RecoverPassword from '@/modules/recover-password/recover-password.vue';
import PrivacyPolicy from '@/modules/privacy-policy/privacy-policy.vue';

import InsideSales from '@/pages/inside-sales/routes';
import Company from '@/pages/company/routes';
import CompanyType from '@/pages/config/company-type/routes';
import CompanySegment from '@/pages/config/company-segment/routes';
import EconomicSector from '@/pages/config/economic-sector/routes';
import Contact from '@/pages/contact/routes';
import ContactType from '@/pages/config/contact-type/routes';
import SystemAccessProfile from '@/pages/config/system-access-profile/routes';
import Users from '@/pages/config/system-user/routes';
import DocumentType from '@/pages/config/document-type/routes';
import TypeMedia from '@/pages/config/type-media/routes';
import ProductType from '@/pages/config/product-type/routes';
import PriceList from '@/pages/price-list/routes';
import CarGroup from '@/pages/car-group/routes';
import City from '@/pages/city/routes';
import Order from '@/pages/order/routes';

const toast = useToast();

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      InsideSales,
      Company,
      CompanyType,
      CompanySegment,
      EconomicSector,
      Contact,
      ContactType,
      Users,
      SystemAccessProfile,
      DocumentType,
      TypeMedia,
      ProductType,
      PriceList,
      CarGroup,
      City,
      Order,
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: '/recover-password',
    name: 'RecoverPassword',
    component: RecoverPassword,
    props: (route) => ({ token: route.query.t }),
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
];

const router = createRouter({
  history: createWebHistory('/#/'),
  routes,
});

router.beforeEach((to: any, from: any, next) => {
  const modules = store.getters['auth/modules'] || [];
  const expiresIn = store.getters['auth/expiresIn'] || 0;

  if (to.meta.path !== '/login' && to.meta.requiresAuth && expiresIn * 1000 < Date.now()) {
    store.dispatch('auth/logout');
  } else {
    if (to.meta.requiresAuth && !store.getters['auth/token']) {
      next('/login');
    } else if (to.meta.requiresUnauth && !!store.getters['auth/token']) {
      next('/');
    } else {
      let ok = true;

      if (to.meta.modulePermission) {
        const modulo = modules.find((i: LoginModule) => i.module === to.meta.modulePermission);

        if (!modulo || !to.meta.requiresPermission || !modulo[to.meta.requiresPermission]) {
          ok = false;
          toast.warning('Permissão negada.');
        }
      }

      if (ok) {
        next();
      }
    }
  }
});

export default router;
