import config from '@/config/config';

import Index from '@/pages/order/index.vue';
import List from '@/pages/order/list/list.vue';
import View from '@/pages/order/view/view.vue';
import New from '@/pages/order/new/new.vue';
import Edit from '@/pages/order/edit/edit.vue';
import SlotsMap from '@/pages/order/slots-map/slots-map.vue';

const modulePermission = config.modules.order;

export default {
  path: '/order',
  name: 'order',
  component: Index,
  meta: {
    requiresAuth: true,
    modulePermission: modulePermission,
    requiresPermission: 'enabled_read',
  },
  children: [
    {
      path: 'slots-map',
      name: 'order-slots-map',
      component: SlotsMap,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_read',
      },
    },
    {
      path: 'list',
      name: 'order-list',
      component: List,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_read',
      },
    },
    {
      path: ':id',
      name: 'order-view',
      component: View,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_read',
      },
      props: true,
    },
    {
      path: 'new',
      name: 'order-new',
      component: New,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_create',
      },
    },
    {
      path: ':id/e',
      name: 'order-edit',
      component: Edit,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_edit',
      },
      props: true,
    },
  ],
};
