import { Options, Vue } from 'vue-class-component';
import masks from 'magic-masks';

import config from '@/config/config';
import PageBaseView from '@/pages/PageBaseView';
import * as InsideSalesService from '@/services/inside-sales.service';
import { IInsideSales } from '@/domain/inside-sales.model';

@Options({
  name: 'inside-sales-view',
  props: {
    id: String,
  },
})
export default class InsideSalesView extends PageBaseView {
  public row: IInsideSales = {
    cpf: '',
    name: '',
    email: '',
    phone1: '',
    phone2: '',
    description: '',
    enabled: false,
  };

  public mounted(): void {
    this.url = 'inside-sales';
    this.module = config.modules.insideSales;

    InsideSalesService.get(this.id)
      .then((res: IInsideSales) => {
        if (res && res.id) {
          this.row = res;

          this.row.cpf = masks.cpf(this.row.cpf);
          this.row.phone1 = this.row.phone1 ? masks.cellphoneOrPhone(this.row.phone1) : '';
          this.row.phone2 = this.row.phone2 ? masks.cellphoneOrPhone(this.row.phone2) : '';
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }
}
