import axios from 'axios';

import config from '@/config/config';
import { headers } from './auth.headers';
import { formatQueryString } from '@/helpers/query-string';
import { ICompanySegment } from '@/domain/company-segment.model';
import { IPaginateVueTable } from '@/interfaces/IPaginateVueTable';

const url_api_base = config.url_api_cliente;

export const insert = (data: ICompanySegment): Promise<ICompanySegment> => {
  return axios.post(`${url_api_base}/company-segment`, data, headers());
};

export const update = (data: ICompanySegment): Promise<ICompanySegment> => {
  return axios.patch(`${url_api_base}/company-segment/${data.id}`, data, headers());
};

export const get = (id: string): Promise<ICompanySegment> => {
  const url = `${url_api_base}/company-segment/${id}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const list = (
  offset?: number,
  limit?: number,
  order?: string,
  sort?: string,
  filter?: string,
): Promise<IPaginateVueTable<ICompanySegment>> => {
  const query = formatQueryString(offset, limit, order, sort, filter);
  const url = `${url_api_base}/company-segment${query}`;

  return axios.get(url, headers()).then((res) => res?.data);
};
