import { reactive } from 'vue';
import { Options } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import Toggle from '@vueform/toggle';
import dayjs from 'dayjs';

import config from '@/config/config';
import PageBaseList from '@/pages/PageBaseList';
import TableLite from '@/components/table/TableLiteTs.vue';
import { IList } from '@/domain/list.model';
import * as CityService from '@/services/city.service';
import * as CarGroupService from '@/services/car-group.service';
import * as OrderSlotService from '@/services/order-slot.service';
import * as OrderEconomicSectorService from '@/services/order-economic-sector.service';
import * as ProductTypeService from '@/services/product-type.service';
import { ICity } from '@/domain/city.model';
import { ICarGroup } from '@/domain/car-group.model';
import { IOrderSlot } from '@/domain/order-slot.model';
import { IProductType } from '@/domain/product-type.model';
import { IOrderEconomicSector } from '@/domain/order-economic-sector.model';
import { OrderSlotsShowEnum } from '@/domain/enum/OrderSlots.enum';
import { ProductTypeEnum } from '@/domain/enum/ProductType.enum';

@Options({
  name: 'city-list',
  components: {
    TableLite,
    Toggle,
  },
})
export default class SlotsMap extends PageBaseList {
  private toast = useToast();
  private days = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];
  private secondsPerSlot = 15;
  private pixelsPerSlot = 44;
  private count_slots_main: any = {};
  private count_slots_inf: any = {};
  public showNewOrder = false;
  public showSlotsToSelect = false;
  public maxRangePeriod = 90;
  public hasError = false;
  public errorMessage = '';
  public errors: any = {};
  public errors_filter: any = {};
  public filterSlot: any = {
    city: 0,
    carGroup: 0,
    period: reactive([]),
  };
  public carGroupsSelected: ICarGroup[] = reactive([]);
  public cities_raw: ICity[] = reactive([]);
  public cities: IList[] = reactive([]);
  public car_groups_raw: ICarGroup[] = reactive([]);
  public car_groups: IList[] = reactive([]);
  public economic_sectors: IList[] = reactive([]);
  public product_types: IList[] = reactive([]);
  public car_groups_city: IList[] = reactive([]);
  public order_slots_main: any = {};
  public order_slots_inf: any = {};
  public dates: { date: string; day: string; day_week: string }[] = reactive([]);
  public row: any = {
    city: 0,
    duration: '',
    period_start: '',
    period_end: '',
    economic_sector: 0,
    exclusive: false,
    product_type: 0,
    quantity_cars: 0,
  };
  public groupsToInsert: any[] = [];

  public mounted(): void {
    this.maxRangePeriod = config.orderSlotsMaxShowDays;

    CityService.listActive().then((res: ICity[]) => {
      this.cities = res.map((i) => ({ id: i.id, description: i.city }));
      this.cities_raw = res;
    });

    CarGroupService.listActive().then((res: ICarGroup[]) => {
      this.car_groups = res.map((i) => ({ id: i.id, description: i.description }));
      this.car_groups_raw = res;
    });

    // OrderEconomicSectorService.listActive().then((res: IOrderEconomicSector[]) => {
    //   this.economic_sectors = res.map((i) => ({ id: i.id, description: i.name }));
    // });
    this.economic_sectors.push({ id: 1, description: 'Alimentação' });

    ProductTypeService.listActive().then((res: IProductType[]) => {
      this.product_types = res.map((i) => ({ id: i.id, description: i.description }));
    });

    let periodStart = dayjs().startOf('day');

    if (config.orderSlotsShowStartFrom == OrderSlotsShowEnum.FirstDayMonthOrWeek) {
      periodStart = periodStart.startOf(config.orderSlotsDefualtShowDays == 7 ? 'week' : 'month');
    }

    this.filterSlot.period.push(periodStart.toDate());
    this.filterSlot.period.push(periodStart.add(config.orderSlotsDefualtShowDays, 'day').endOf('day').toDate());

    let ini = periodStart;
    let k = 0;
    while (k <= config.orderSlotsDefualtShowDays) {
      const date = '' + ini.format('YYYYMMDD');

      this.dates.push({
        date,
        day: String(ini.date()).padStart(2, '0'),
        day_week: this.days[ini.day()],
      });

      this.order_slots_main[date] = Array.from({ length: 24 });
      this.order_slots_inf[date] = Array.from({ length: 24 });
      this.count_slots_main[date] = 0;
      this.count_slots_inf[date] = 0;

      k++;
      ini = ini.add(1, 'day');
    }
  }

  public loadSlots(
    city: number,
    car_group: number,
    period_start: Date,
    period_end: Date,
  ) {
    Object.keys(this.order_slots_main).forEach((i) => (this.order_slots_main[i] = Array.from({ length: 24 })));
    Object.keys(this.order_slots_inf).forEach((i) => (this.order_slots_inf[i] = Array.from({ length: 24 })));
    Object.keys(this.count_slots_main).forEach((i) => (this.count_slots_main[i] = 0));
    Object.keys(this.count_slots_inf).forEach((i) => (this.count_slots_inf[i] = 0));

    OrderSlotService.listByPeriod(city, car_group, period_start, period_end)
      .then((res: IOrderSlot[]) => {
        res.forEach(i => {
          let start = dayjs(i.start_date);
          const end = dayjs(i.end_date);

          while (start <= end) {
            const key = '' + start.format('YYYYMMDD');

            if (this.order_slots_main[key]) {
              (i.product_type == ProductTypeEnum.Principal)
                ? this.count_slots_main[key] = this.fillSlots(i, this.order_slots_main[key], this.count_slots_main[key])
                : this.count_slots_inf[key] = this.fillSlots(i, this.order_slots_inf[key], this.count_slots_inf[key]);
            }

            start = start.add(1, 'day');
          }
        });
      });
  }

  private fillSlots(data: IOrderSlot, slotsArea: any[], k: number): number {
    if (!slotsArea[k]) {
      slotsArea[k] = {
        pixels: this.pixelsPerSlot,
        start: false,
        items: [],
      };
    }

    let size = Math.round(((data.time * this.pixelsPerSlot) / this.secondsPerSlot) * 10) / 10;

    if (size <= slotsArea[k].pixels) {
      this.addSlot(slotsArea[k], data, size);
    } else {
      let sizePart = slotsArea[k].pixels;
      let start = true;
      let end = false;
      let middle = false;

      do {
        this.addSlot(slotsArea[k], data, sizePart, start, end, middle);

        size = size - sizePart;

        if (size > slotsArea[k].pixels) {
          slotsArea[k].start = true;

          k++;

          if (!slotsArea[k]) {
            slotsArea[k] = {
              pixels: this.pixelsPerSlot,
              start: false,
              items: [],
            };
          }

          sizePart = size <= slotsArea[k].pixels ? size : slotsArea[k].pixels;
          start = false;
          end = sizePart == size;
          middle = sizePart == slotsArea[k].pixels && size > slotsArea[k].pixels;
        } else {
          sizePart = size;
          end = false;
        }
      } while (sizePart > 0);
    }

    return slotsArea[k].pixels == 0 ? k + 1 : k;
  }

  private addSlot(
    slotArea: any,
    data: IOrderSlot,
    size: number,
    start: boolean = false,
    end: boolean = false,
    middle: boolean = false,
  ) {
    slotArea.items.push({
      size,
      start,
      end,
      middle,
      data: {
        campaign: data.description,
        company_name: data.company_name,
        start: dayjs(data.start_date).format('DD/MM/YYYY'),
        end: dayjs(data.end_date).format('DD/MM/YYYY'),
        time: data.time,
      },
    });

    slotArea.pixels -= size;
  }

  public doFilter() {
    this.errors_filter = {};
    this.hasError = false;

    this.validatorField(this.filterSlot, 'city', 'Selecione uma cidade.', this.errors_filter);
    this.validatorField(this.filterSlot, 'carGroup', 'Selecione um grupo de carros.', this.errors_filter);

    if (!this.hasError) {
      this.loadSlots(this.filterSlot.city, this.filterSlot.carGroup, this.filterSlot.period[0], this.filterSlot.period[1]);
    }
  }

  public doFilterSlotsToSelect() {
    this.errors = {};
    this.hasError = false;

    this.validatorField(this.row, 'city', 'Selecione uma cidade.', this.errors);
    this.validatorField(this.row, 'duration', 'Selecione uma duração.', this.errors);
    this.validatorField(this.row, 'period_start', 'Selecione um período de início.', this.errors);
    this.validatorField(this.row, 'period_end', 'Selecione um período de fim.', this.errors);
    this.validatorField(this.row, 'economic_sector', 'Selecione um segmento.', this.errors);
    this.validatorField(this.row, 'product_type', 'Selecione uma área.', this.errors);
    this.validatorField(this.row, 'quantity_cars', 'Selecione uma quantidade de carros.', this.errors);

    if (!this.hasError) {
      this.showSlotsToSelect = true;
      this.loadSlotsAvailable(
        this.row.city,
        this.row.duration,
        this.row.period_start,
        this.row.period_end,
        this.row.economic_sector,
        this.row.product_type,
        this.row.quantity_cars,
      );
    }
  }

  public loadSlotsAvailable(
    city: number,
    duration: number,
    period_start: Date,
    period_end: Date,
    economic_sector: number,
    product_type: number,
    quantity_cars: number,
  ) {
    this.carGroupsSelected = [];

    let cars = quantity_cars;
    this.car_groups_raw.forEach((i) => {
      if (cars > 0) {
        this.carGroupsSelected.push(i);
        cars -= i.total_cars;
      }
    });

    // OrderSlotService.listAvailableCarGroups(city, duration, period_start, period_end, economic_sector, product_type, quantity_cars)
    //   .then((res: ICarGroup[]) => {
    //     this.carGroupsSelected = res;
    //   });
  }

  private validatorField(fieldList: any, field: string, msg: string, errosList: any) {
    if (!fieldList[field]) {
      errosList[field] = msg;
      this.toast.warning(msg);
      this.hasError = true;
    }
  }

  public newOrder() {
    this.errors = {};
    this.hasError = false;
    this.clearNewOrderForm();
    this.showNewOrder = true;
  }

  public clearNewOrderForm() {
    this.row = {
      city: 0,
      duration: '',
      period_start: '',
      period_end: '',
      economic_sector: 0,
      exclusive: false,
      product_type: 0,
      quantity_cars: 0,
    };
    this.showSlotsToSelect = false;
    this.carGroupsSelected = [];
  }

  public closeModal() {
    this.showNewOrder = false;
    this.groupsToInsert = [];
  }

  public addGroupsToOrder() {
    this.groupsToInsert.push({
      city: this.row.city,
      city_desc: this.cities_raw.find((i) => i.id == this.row.city).city,
      duration: this.row.duration,
      period_start: this.row.period_start,
      period_end: this.row.period_end,
      economic_sector: this.row.economic_sector,
      product_type: this.row.product_type,
      quantity_cars: this.row.quantity_cars,
      car_groups_selected: this.carGroupsSelected,
      car_groups_selected_desc: this.carGroupsSelected.map((i) => i.name).join(', '),
    });

    this.clearNewOrderForm();
  }

  public selectCity() {
    let sum = 0;
    const citySelected = this.cities_raw.find((i) => i.id == this.row.city);

    this.car_groups_city = this.car_groups_raw
      .sort((a, b) => (a.total_cars > b.total_cars ? 1 : b.total_cars > a.total_cars ? -1 : 0))
      .filter((i) => i.id_city == this.row.city)
      .map((i) => {
        sum += i.total_cars;
        i.total_cars = sum;
        return i;
      })
      .filter((i) => i.total_cars <= citySelected.max_order_cars)
      .map((i) => ({ id: i.id, description: `${i.total_cars}` }));
  }

  public formatDatePreview(d: Date[]) {
    return `${dayjs(d[0]).format('DD/MM/YYYY')} - ${dayjs(d[1]).format('DD/MM/YYYY')}`;
  }

  public formatDateSimplePreview(d: Date) {
    return dayjs(d).format('DD/MM/YYYY');
  }
}
