import menu from './menu';
import modules from './modules';
import version from './version';

let config = Object.assign(version, {
  url_api_base: (window as any).__INITIAL_DATA__.url_api_base,
  url_api_user: (window as any).__INITIAL_DATA__.url_api_user,
  url_api_cliente: (window as any).__INITIAL_DATA__.url_api_cliente,
  url_api_pedido: (window as any).__INITIAL_DATA__.url_api_pedido,
  app_url_base: (window as any).__INITIAL_DATA__.PUBLIC_URL,
  MAX_ATTACHMENT_SIZE: 10000000,
  menu: menu,
  modules: modules,
  orderSlotsDefualtShowDays: 30, // ORDER_SLOTS_DEFAULT_SHOW_DAYS from system_param
  orderSlotsMaxShowDays: 90, // ORDER_SLOTS_MAX_SHOW_DAYS from system_param
  orderSlotsShowStartFrom: 'FIRST_DAY_SELECTED', // ORDER_SLOTS_SHOW_START_FROM from system_param
});

export const load = (setup: any) => {
  config = Object.assign(config, setup);
};

export default config;
