import { Options, Vue } from 'vue-class-component';

import List from '@/pages/inside-sales/list/list.vue';

@Options({
  name: 'inside-sales',
  components: {
    'page-list': List,
  },
})
export default class InsideSales extends Vue {
  public isIndexOnly() {
    return /^[/]?inside-sales[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
