import axios from 'axios';

import config from '@/config/config';
import { headers } from './auth.headers';
import { formatQueryString } from '@/helpers/query-string';
import { ISystemUser } from '@/domain/system-user.model';
import { IPaginateVueTable } from '@/interfaces/IPaginateVueTable';
import { LoginRequest, LoginResponse } from '@/domain/login.model';
import { ChangePassword } from '@/domain/change-password.model';

const url_api_base = config.url_api_user;

export const insert = (data: ISystemUser): Promise<ISystemUser> => {
  return axios.post(`${url_api_base}/system-user`, data, headers());
};

export const update = (data: ISystemUser): Promise<ISystemUser> => {
  return axios.patch(`${url_api_base}/system-user/${data.id}`, data, headers());
};

export const changePassword = (
  userId: number,
  password: string,
  confirmPassword: string,
): Promise<ISystemUser> => {
  const url = `${url_api_base}/system-user/${userId}/change-password`;
  const data = {
    password,
    confirmPassword,
  };

  return axios.patch(url, data, headers());
};

export const get = (id: string): Promise<ISystemUser> => {
  const url = `${url_api_base}/system-user/${id}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const list = (
  offset?: number,
  limit?: number,
  order?: string,
  sort?: string,
  filter?: string,
): Promise<IPaginateVueTable<ISystemUser>> => {
  const query = formatQueryString(offset, limit, order, sort, filter);
  const url = `${url_api_base}/system-user${query}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const login = (data: LoginRequest): Promise<LoginResponse> => {
  return axios.post(`${url_api_base}/auth/login`, data, headers()).then((res) => res?.data);
};

export const forgotPassword = (username: string): Promise<ISystemUser> => {
  return axios.get(`${url_api_base}/auth/forgot-password/${username}`, headers()).then((res) => res?.data);
};

export const recoverPassword = (data: ChangePassword): Promise<ChangePassword> => {
  return axios.post(`${url_api_base}/auth/change-password`, data, headers()).then((res) => res?.data);
};
