import config from '@/config/config';

import Index from '@/pages/inside-sales/index.vue';
import List from '@/pages/inside-sales/list/list.vue';
import View from '@/pages/inside-sales/view/view.vue';
import New from '@/pages/inside-sales/new/new.vue';
import Edit from '@/pages/inside-sales/edit/edit.vue';

const modulePermission = config.modules.insideSales;

export default {
  path: '/inside-sales',
  name: 'inside-sales',
  component: Index,
  meta: {
    requiresAuth: true,
    modulePermission: modulePermission,
    requiresPermission: 'enabled_read',
  },
  children: [
    {
      path: 'list',
      name: 'inside-sales-list',
      component: List,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_read',
      },
    },
    {
      path: ':id',
      name: 'inside-sales-view',
      component: View,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_read',
      },
      props: true,
    },
    {
      path: 'new',
      name: 'inside-sales-new',
      component: New,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_create',
      },
    },
    {
      path: ':id/e',
      name: 'inside-sales-edit',
      component: Edit,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_edit',
      },
      props: true,
    },
  ],
};
