export const formatQueryString = (
  offset?: number,
  limit?: number,
  order?: string,
  sort?: string,
  filter?: string,
): string => {
  let query = '';

  if (offset) {
    query += `&page=${offset}`;
  }

  if (limit) {
    query += `&per_page=${limit}`;
  }

  if (order) {
    query += `&sort=${order}`;

    if (sort) {
      query += `|${sort}`;
    }
  }

  if (filter) {
    query += `&filter=${filter}`;
  }

  if (query) {
    query = `?${query.slice(1)}`;
  }

  return query;
};
