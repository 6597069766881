import { Options, Vue } from 'vue-class-component';

import List from '@/pages/config/company-segment/list/list.vue';

@Options({
  name: 'company-segment',
  components: {
    'page-list': List,
  },
})
export default class CompanySegment extends Vue {
  public isIndexOnly() {
    return /^[/]?company-segment[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
