import { reactive } from 'vue';
import { Options } from 'vue-class-component';

import config from '@/config/config';
import PageBaseList from '@/pages/PageBaseList';
import TableLite from '@/components/table/TableLiteTs.vue';
import * as ProductTypeService from '@/services/product-type.service';
import { IPaginateVueTable } from '@/interfaces/IPaginateVueTable';
import { IProductType } from '@/domain/product-type.model';

@Options({
  name: 'product-type-list',
  components: {
    TableLite,
  },
})
export default class ProductTypeList extends PageBaseList {
  public table = TABLE;

  public mounted(): void {
    this.url = 'product-type';
    this.module = config.modules.productType;
    this.table.rows = [];

    this.doSearch(0, this.per_page, 'id', 'asc');
  }

  public doSearch(
    offset?: number,
    limit?: number,
    order?: string,
    sort?: string,
    filter?: string,
  ) {
    this.table.isLoading = true;

    this.table.isReSearch = offset == undefined ? true : false;
    if (offset >= 10 || limit >= this.per_page) {
      limit = this.per_page;
    }

    ProductTypeService.list(offset, limit, order, sort, filter).then((res: IPaginateVueTable<IProductType>) => {
      this.table.rows = res?.data;
      this.table.totalRecordCount = res?.total;
      this.table.sortable.order = order;
      this.table.sortable.sort = sort;
    });
  }
}

export const TABLE = reactive({
  isLoading: false,
  isReSearch: false,
  columns: [
    {
      label: 'Id',
      field: 'id',
      sortable: true,
      isKey: true,
    },
    {
      label: 'Descrição',
      field: 'description',
      sortable: true,
      columnStyles: 'text-align: left',
    },
    {
      label: 'Ativo',
      field: 'enabled',
      sortable: true,
    },
    {
      field: 'actions',
    },
  ],
  rows: [{}],
  totalRecordCount: 0,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});
