import { Options, Vue } from 'vue-class-component';
import { isCpf, isEmail } from 'node-simple-validator';

import { onlyNumbers } from '@/helpers/numbers';
import * as InsideSalesService from '@/services/inside-sales.service';

@Options({
  name: 'inside-sales-new',
})
export default class InsideSalesNew extends Vue {
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public row = {
    cpf: '',
    name: '',
    email: '',
    phone1: '',
    phone2: '',
    description: '',
    enabled: '',
  };

  public goBack() {
    this.$router.push('/inside-sales');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.name) {
      this.errors.name = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.cpf) {
      this.errors.cpf = 'Este campo deve ser preenchido.';
      this.hasError = true;
    } else if (!isCpf(this.row.cpf)) {
      this.errors.cpf = 'CPF inválido.';
      this.hasError = true;
    }

    if (!this.row.email) {
      this.errors.email = 'Este campo deve ser preenchido.';
      this.hasError = true;
    } else if (!isEmail(this.row.email)) {
      this.errors.email = 'E-mail inválido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      InsideSalesService.insert({
        name: this.row.name,
        cpf: onlyNumbers(this.row.cpf),
        email: this.row.email,
        phone1: this.row.phone1 ? onlyNumbers(this.row.phone1) : undefined,
        phone2: this.row.phone2 ? onlyNumbers(this.row.phone2) : undefined,
        description: this.row.description,
      })
        .then(() => {
          this.$router.push('/inside-sales');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
