import { createApp } from 'vue';
import App from './app/app.vue';
import router from './router';
import store from './store';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faGooglePlus } from '@fortawesome/free-brands-svg-icons';
import Toast, { PluginOptions } from 'vue-toastification';
import { createI18n } from 'vue-i18n';
import { VueWindowSizePlugin } from 'vue-window-size/option-api';
import Maska from 'maska';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import BootstrapVue3 from 'bootstrap-vue-3';

import en from './translation/en.json';
import es from './translation/es.json';
import pt from './translation/pt.json';
import './index.scss';

library.add(faLock, faEnvelope, faFacebook, faGooglePlus);

const options: PluginOptions = {
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
};
const i18n = createI18n({
  locale: 'pt',
  messages: { en, es, pt },
  fallbackLocale: 'pt',
});

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('Datepicker', Datepicker)
  .use(store)
  .use(router)
  .use(VueWindowSizePlugin)
  .use(Toast, options)
  .use(i18n)
  .use(Maska)
  .use(BootstrapVue3)
  .mount('#app');
