import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';

import * as CarGroupService from '@/services/car-group.service';
import { ICarGroup } from '@/domain/car-group.model';
import * as CityService from '@/services/city.service';
import { IList } from '@/domain/list.model';
import { ICity } from '@/domain/city.model';

@Options({
  name: 'car-group-edit',
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class CarGroupEdit extends Vue {
  public id: string;
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public city: IList[] = reactive([]);
  public row: ICarGroup = {
    id_city: 0,
    name: '',
    description: '',
    city_description:  '',
    total_cars: 0,
    enabled: false,
  };

  public created(): void {
    CityService.listActive()
      .then((res: ICity[]) => {
          this.city = res.map((i) => {
          return { id: i.id, description: i.city };
        });
      });
  }

  public mounted(): void {
    CarGroupService.get(this.id)
      .then((res: ICarGroup) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public goBack() {
    this.$router.push('/car-group');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    this.row.name = this.row.name?.trim();
    this.row.description = this.row.description?.trim();

    if (!this.row.name) {
      this.errors.name = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.description) {
      this.errors.description = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      CarGroupService
        .update({
          id: parseInt(this.id),
          id_city: this.row.id_city,
          name: this.row.name,
          description: this.row.description,
          total_cars: this.row.total_cars,
          enabled: this.row.enabled,
        })
        .then(() => {
          this.$router.push('/car-group');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
