import { Options, Vue } from 'vue-class-component';
import { reactive } from 'vue';

import config from '@/config/config';
import PageBaseView from '@/pages/PageBaseView';
import * as SystemAccessProfileService from '@/services/system-access-profile.service';
import * as SystemModuleProfileService from '@/services/system-module-profile.service';
import { ISystemAccessProfile } from '@/domain/system-access-profile.model';
import { ISystemModuleProfile } from '@/domain/system-module-profile.model';

@Options({
  name: 'system-access-profile-view',
  props: {
    id: String,
  },
})
export default class SystemAccessProfileView extends PageBaseView {
  public systemModuleProfile: ISystemModuleProfile[] = reactive([]);
  public row: ISystemAccessProfile = {
    description: '',
    name: '',
    enabled: false,
  };

  public mounted(): void {
    this.url = 'system-access-profile';
    this.module = config.modules.systemAccessProfile;

    SystemAccessProfileService.get(this.id)
      .then((res: ISystemAccessProfile) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });

    this.loadSystemModuleProfile();
  }

  public loadSystemModuleProfile() {
    SystemModuleProfileService.getModulesByProfileId(this.id)
      .then((res: ISystemModuleProfile[]) => {
        this.systemModuleProfile = res;
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }
}
