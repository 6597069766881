import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import { IAuthModule } from '@/interfaces/state';

const authModule: IAuthModule = {
  namespaced: true,
  state: {
    token: localStorage.getItem('token'),
    expiresIn: parseInt(localStorage.getItem('expiresIn')) || 0,
    user: JSON.parse(localStorage.getItem('user')),
    modules: JSON.parse(localStorage.getItem('modules')),
  },
  mutations,
  actions,
  getters,
};

export default authModule;
