import axios from 'axios';

import config from '@/config/config';
import { headers } from './auth.headers';
import { formatQueryString } from '@/helpers/query-string';
import { IPaginateVueTable } from '@/interfaces/IPaginateVueTable';
import { ICompany } from '@/domain/company.model';
import { ICompanyList } from '@/domain/company-list.model';

const url_api_base = config.url_api_cliente;

export const insert = (data: ICompany): Promise<ICompany> => {
  return axios.post(`${url_api_base}/company`, data, headers());
};

export const update = (data: ICompany): Promise<ICompany> => {
  return axios.patch(`${url_api_base}/company/${data.id}`, data, headers());
};

export const get = (id: string): Promise<ICompany> => {
  const url = `${url_api_base}/company/${id}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const list = (
  offset?: number,
  limit?: number,
  order?: string,
  sort?: string,
  filter?: string,
  onlyEnabled?: boolean,
): Promise<IPaginateVueTable<ICompany>> => {
  let query = formatQueryString(offset, limit, order, sort, filter);

  if (onlyEnabled) {
    query = `${query ? query + '&' : '?'}only_enabled=${onlyEnabled}`;
  }

  const url = `${url_api_base}/company${query}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const listsToForm = (): Promise<ICompanyList> => {
  const url = `${url_api_base}/company/lists-to-form`;

  return axios.get(url, headers()).then((res) => res?.data);
};
