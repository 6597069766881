import { Options, Vue } from 'vue-class-component';
import masks from 'magic-masks';

import config from '@/config/config';
import PageBaseView from '@/pages/PageBaseView';
import * as ContactService from '@/services/contact.service';
import { IContact } from '@/domain/contact.model';

@Options({
  name: 'contact-view',
  props: {
    id: String,
  },
})
export default class ContactView extends PageBaseView {
  public row: IContact = {
    id_company: 0,
    contact_type: 0,
    name: '',
    email: '',
    phone1: '',
    phone2: '',
    description: '',
    enabled: false,
    authorization_email: false,
    authorization_sms: false,
    authorization_whatsapp: false,
  };

  public mounted(): void {
    this.url = 'contact';
    this.module = config.modules.contact;

    ContactService.get(this.id)
      .then((res: IContact) => {
        if (res && res.id) {
          this.row = res;

          this.row.phone1 = this.row.phone1 ? masks.cellphoneOrPhone(this.row.phone1) : '';
          this.row.phone2 = this.row.phone2 ? masks.cellphoneOrPhone(this.row.phone2) : '';
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public goBack() {
    this.$router.push('/company/' + this.row.id_company);
  }
}
