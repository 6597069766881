import axios from 'axios';

import config from '@/config/config';
import { headers } from './auth.headers';
import { formatQueryString } from '@/helpers/query-string';
import { ISystemAccessProfile } from '@/domain/system-access-profile.model';
import { IPaginateVueTable } from '@/interfaces/IPaginateVueTable';

const url_api_base = config.url_api_user;

export const insert = (data: ISystemAccessProfile): Promise<ISystemAccessProfile> => {
  return axios.post(`${url_api_base}/system-access-profile`, data, headers());
};

export const update = (data: ISystemAccessProfile): Promise<ISystemAccessProfile> => {
  return axios.patch(`${url_api_base}/system-access-profile/${data.id}`, data, headers());
};

export const get = (id: string): Promise<ISystemAccessProfile> => {
  const url = `${url_api_base}/system-access-profile/${id}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const list = (
  offset?: number,
  limit?: number,
  order?: string,
  sort?: string,
  filter?: string,
): Promise<IPaginateVueTable<ISystemAccessProfile>> => {
  const query = formatQueryString(offset, limit, order, sort, filter);
  const url = `${url_api_base}/system-access-profile${query}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const listActive = (): Promise<ISystemAccessProfile[]> => {
  const url = `${url_api_base}/system-access-profile/list-active`;

  return axios.get(url, headers()).then((res) => res?.data);
};
export function getMediaTypes(id: string) {
  throw new Error('Function not implemented.');
}

export function getSystemModuleProfile(id: string) {
  throw new Error('Function not implemented.');
}

