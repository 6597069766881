import { Options, Vue } from 'vue-class-component';
import masks from 'magic-masks';

import config from '@/config/config';
import PageBaseView from '@/pages/PageBaseView';
import * as SystemUserService from '@/services/system-user.service';
import { ISystemUser } from '@/domain/system-user.model';

@Options({
  name: 'system-user-view',
  props: {
    id: String,
  },
})
export default class SystemUserView extends PageBaseView {
  public row: ISystemUser = {
    name: '',
    email: '',
    cpf: '',
    phone: '',
    username: '',
    id_access_profile: 0,
    enabled: false,
  };

  public mounted(): void {
    this.url = 'system-user';
    this.module = config.modules.systemUser;

    SystemUserService.get(this.id)
      .then((res: ISystemUser) => {
        if (res && res.id) {
          this.row = res;

          this.row.cpf = this.row.cpf ? masks.cpf(this.row.cpf) : '';
          this.row.phone = this.row.phone ? masks.cellphoneOrPhone(this.row.phone) : '';
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }
}
