import modules from './modules';

const menu = [
  {
    name: 'labels.dashboard',
    path: '/',
    icon: 'fas fa-tachometer-alt',
    // modulePermission: modules.dashboard,
  },
  {
    name: 'labels.companyMenu',
    path: '/company',
    icon: 'fas fa-window-restore',
    modulePermission: modules.company,
  },
  {
    name: 'labels.productMenu',
    path: '/price-list',
    icon: 'fas fa-swatchbook',
    modulePermission: modules.priceList,
  },
  {
    name: 'labels.orderMenu',
    path: '/order/slots-map',
    icon: 'fas fa-share-square',
    modulePermission: modules.order,
  },
  {
    name: 'labels.insideSalesMenu',
    path: '/inside-sales',
    icon: 'fas fa-male',
    modulePermission: modules.insideSales,
  },
  {
    name: 'labels.carGroupMenu',
    path: '/car-group',
    icon: 'fas fa-car',
    modulePermission: modules.carGroup,
  },
  {
    name: 'labels.cityMenu',
    path: '/city',
    icon: 'fas fa-city',
    modulePermission: modules.city,
  },
  {
    name: 'labels.configMenu',
    icon: 'fas fa-cogs',
    path: '#config',
    children: [
      {
        name: 'labels.companyMenu',
        path: '#config-company',
        children: [
          {
            name: 'labels.companyTypeMenu',
            path: '/company-type',
            modulePermission: modules.companyType,
          },
          {
            name: 'labels.companySegmentMenu',
            path: '/company-segment',
            modulePermission: modules.companySegment,
          },
          {
            name: 'labels.economicSectorMenu',
            path: '/economic-sector',
            modulePermission: modules.economicSector,
          },
          {
            name: 'labels.contactTypeMenu',
            path: '/contact-type',
            modulePermission: modules.contactType,
          },
        ],
      },
      {
        name: 'labels.productMenu',
        path: '#config-product',
        children: [
          {
            name: 'labels.documentTypeMenu',
            path: '/document-type',
            modulePermission: modules.documentType,
          },
          {
            name: 'labels.typeMediaMenu',
            path: '/type-media',
            modulePermission: modules.typeMedia,
          },
          {
            name: 'labels.productTypeMenu',
            path: '/product-type',
            modulePermission: modules.productType,
          },
        ],
      },
      {
        name: 'labels.systemUserMenu',
        path: '#config-user',
        children: [
          {
            name: 'labels.systemUserMenu',
            path: '/system-user',
            modulePermission: modules.systemUser,
          },
          {
            name: 'labels.systemAccessProfileMenu',
            path: '/system-access-profile',
            modulePermission: modules.systemAccessProfile,
          },
        ],
      },
    ],
  },
];

export default menu;
