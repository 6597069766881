import axios from 'axios';
import dayjs from 'dayjs';

import config from '@/config/config';
import { headers } from './auth.headers';
import { formatQueryString } from '@/helpers/query-string';
import { IOrderSlot } from '@/domain/order-slot.model';
import { IPaginateVueTable } from '@/interfaces/IPaginateVueTable';
import { ICarGroup } from '@/domain/car-group.model';

const url_api_base = config.url_api_pedido;

export const insert = (data: IOrderSlot): Promise<IOrderSlot> => {
  return axios.post(`${url_api_base}/order-slot`, data, headers());
};

export const update = (data: IOrderSlot): Promise<IOrderSlot> => {
  return axios.patch(`${url_api_base}/order-slot/${data.id}`, data, headers());
};

export const get = (id: string): Promise<IOrderSlot> => {
  const url = `${url_api_base}/order-slot/${id}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const list = (
  offset?: number,
  limit?: number,
  order?: string,
  sort?: string,
  filter?: string,
): Promise<IPaginateVueTable<IOrderSlot>> => {
  const query = formatQueryString(offset, limit, order, sort, filter);
  const url = `${url_api_base}/order-slot${query}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const listByPeriod = (
  city: number,
  car_group: number,
  period_start: Date,
  period_end: Date,
): Promise<IOrderSlot[]> => {
  const query = `?city=${city}&car_group=${car_group}`
  + `&period_start=${dayjs(period_start).format('YYYY-MM-DD')}`
  + `&period_end=${dayjs(period_end).format('YYYY-MM-DD')}`;

  const url = `${url_api_base}/order-slot/list-by-period${query}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const listAvailableCarGroups = (
  city: number,
  duration: number,
  period_start: Date,
  period_end: Date,
  economic_sector: number,
  product_type: number,
  quantity_cars: number,
): Promise<ICarGroup[]> => {
  const query = `?city=${city}&duration=${duration}`
    + `&period_start=${dayjs(period_start).format('YYYY-MM-DD')}`
    + `&period_end=${dayjs(period_end).format('YYYY-MM-DD')}`
    + `&economic_sector=${economic_sector}&product_type=${product_type}&quantity_cars=${quantity_cars}`;

  const url = `${url_api_base}/order-slot/list-available${query}`;

  return axios.get(url, headers()).then((res) => res?.data);
};
