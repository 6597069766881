import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { isCpf, isEmail } from 'node-simple-validator';

import { onlyNumbers } from '@/helpers/numbers';
import * as SystemUserService from '@/services/system-user.service';
import * as SystemAccessProfile from '@/services/system-access-profile.service';
import { IList } from '@/domain/list.model';
import { ISystemAccessProfile } from '@/domain/system-access-profile.model';

@Options({
  name: 'system-user-new',
})
export default class SystemUserNew extends Vue {
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public systemAccessProfiles: IList[] = reactive([]);
  public row = {
    cpf: '',
    name: '',
    email: '',
    phone: '',
    username: '',
    password: '',
    confirmPassword: '',
    id_access_profile: 0,
  };

  public created(): void {
    SystemAccessProfile.listActive()
      .then((res: ISystemAccessProfile[]) => {
        this.systemAccessProfiles = res.map((i) => {
          return { id: i.id, description: i.name };
        });
      });
  }

  public goBack() {
    this.$router.push('/system-user');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.name) {
      this.errors.name = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (this.row.cpf && !isCpf(this.row.cpf)) {
      this.errors.cpf = 'CPF inválido.';
      this.hasError = true;
    }

    if (!this.row.email) {
      this.errors.email = 'Este campo deve ser preenchido.';
      this.hasError = true;
    } else if (!isEmail(this.row.email)) {
      this.errors.email = 'E-mail inválido.';
      this.hasError = true;
    }

    if (!this.row.username) {
      this.errors.username = 'Este campo deve ser preenchido.';
      this.hasError = true;
    } else if (!isEmail(this.row.username)) {
      this.errors.username = 'Login deve ser um e-mail.';
      this.hasError = true;
    }

    if (this.row.confirmPassword !== this.row.password) {
      this.errors.confirmPassword = 'Senha e Confirmação de Senha não conferem.';
      this.hasError = true;
    }

    if (!this.row.password) {
      this.errors.password = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.confirmPassword) {
      this.errors.confirmPassword = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (this.row.password.length < 6) {
      this.errors.password = 'Senha deve ter pelo menos 6 caracteres.';
      this.hasError = true;
    }

    if (!this.row.id_access_profile) {
      this.errors.id_access_profile = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      SystemUserService.insert({
        name: this.row.name,
        cpf: this.row.cpf ? onlyNumbers(this.row.cpf) : undefined,
        email: this.row.email,
        phone: this.row.phone ? onlyNumbers(this.row.phone) : undefined,
        username: this.row.username,
        password: this.row.password,
        confirmPassword: this.row.confirmPassword,
        id_access_profile: this.row.id_access_profile,
      })
        .then(() => {
          this.$router.push('/system-user');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
