import { reactive } from 'vue';
import { Options } from 'vue-class-component';

import config from '@/config/config';
import PageBaseList from '@/pages/PageBaseList';
import TableLite from '@/components/table/TableLiteTs.vue';
import * as CityService from '@/services/city.service';
import { IPaginateVueTable } from '@/interfaces/IPaginateVueTable';
import { ICity } from '@/domain/city.model';

@Options({
  name: 'order-list',
  components: {
    TableLite,
  },
})
export default class OrderList extends PageBaseList {
  public table = TABLE;

  public mounted(): void {
    this.url = 'order';
    this.module = config.modules.order;
    this.table.rows = [];

    this.doSearch(0, this.per_page, 'id', 'asc');
  }

  public doSearch(
    offset?: number,
    limit?: number,
    order?: string,
    sort?: string,
    filter?: string,
  ) {
    this.table.isLoading = true;

    this.table.isReSearch = offset == undefined ? true : false;
    if (offset >= 10 || limit >= this.per_page) {
      limit = this.per_page;
    }

    CityService.list(offset, limit, order, sort, filter).then((res: IPaginateVueTable<ICity>) => {
      this.table.rows = res?.data;
      this.table.totalRecordCount = res?.total;
      this.table.sortable.order = order;
      this.table.sortable.sort = sort;
    });
  }
}

export const TABLE = reactive({
  isLoading: false,
  isReSearch: false,
  columns: [
    {
      label: 'Id',
      field: 'id',
      sortable: true,
      isKey: true,
    },
    {
      label: 'Cidade',
      field: 'city',
      sortable: true,
      columnStyles: 'text-align: left',
    },
    {
      label: 'UF',
      field: 'state',
      sortable: true,
      columnStyles: 'text-align: left',
    },
    {
      label: 'Situação',
      field: 'enabled',
      sortable: true,
    },
    {
      field: 'actions',
    },
  ],
  rows: [{}],
  totalRecordCount: 0,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});
