import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';

import config from '@/config/config';
import PageBaseView from '@/pages/PageBaseView';
import * as PriceListService from '@/services/price-list.service';
import * as TypeMediaService from '@/services/type-media.service';
import { IPriceList } from '@/domain/price-list.model';
import { IPriceListTypeMedia } from '@/domain/price-list-type-media.model';
import { ITypeMedia } from '@/domain/type-media.model';

@Options({
  name: 'price-list-view',
  props: {
    id: String,
  },
})
export default class PriceListView extends PageBaseView {
  public mediaTypes: ITypeMedia[] = reactive([]);
  public productMediaTypes: IPriceListTypeMedia[] = reactive([]);
  public showInsertTypeMedia = false;
  public showDeleteTypeMedia = false;
  public idDeleteTypeMedia = 0;
  public msgDeleteTypeMedia = '';
  public row: IPriceList = {
    name: '',
    description: '',
    price: 0,
    product_type: 0,
    enabled: true,
  };
  public mediaTypesSelected: number[] = [];

  public mounted(): void {
    this.url = 'price-list';
    this.module = config.modules.priceList;

    PriceListService.get(this.id)
      .then((res: IPriceList) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });

    this.loadTypeMedia();

    TypeMediaService.listActive()
      .then((res: ITypeMedia[]) => {
        this.mediaTypes = res;
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public loadTypeMedia() {
    PriceListService.getMediaTypes(this.id)
      .then((res: IPriceListTypeMedia[]) => {
        this.productMediaTypes = res;
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public newTypeMedia() {
    this.mediaTypesSelected = this.productMediaTypes.map((i) => i.id_type_media);
    this.showInsertTypeMedia = true;
  }

  public saveTypeMedia() {
    if (this.mediaTypesSelected.length > 0) {
      this.mediaTypesSelected.forEach((i) => {
        if (!this.typeMediaAlreadyAdded(i)) {
          PriceListService.insertTypeMedia({
            id_price_list: parseInt(this.id),
            id_type_media: i,
            enabled: true,
          })
            .then(() => {
              this.loadTypeMedia();
              this.closeModal();
            })
            .catch(() => {
              this.errorMessage = 'Ocorreu um erro ao gravar.';
            });
        }
      });
    }
  }

  public typeMediaAlreadyAdded(id_type_media: number) {
    return this.productMediaTypes.find((i) => i.id_type_media == id_type_media) != null;
  }

  public deleteTypeMedia(id_type_media: number, tag: string) {
    this.showDeleteTypeMedia = true;
    this.idDeleteTypeMedia = id_type_media;
    this.msgDeleteTypeMedia = `Confirma a remoção do tipo de mídia "${tag}"?`;
  }

  public deleteTypeMediaConfirm() {
    if (this.idDeleteTypeMedia) {
      PriceListService.removerTypeMedia(parseInt(this.id), this.idDeleteTypeMedia)
        .then(() => {
          this.loadTypeMedia();
          this.closeModal();
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao excluir.';
        });
    }
  }

  public closeModal() {
    this.showInsertTypeMedia = false;
    this.showDeleteTypeMedia = false;
  }
}
