import { Options, Vue } from 'vue-class-component';

import List from '@/pages/config/economic-sector/list/list.vue';

@Options({
  name: 'economic-sector',
  components: {
    'page-list': List,
  },
})
export default class EconomicSector extends Vue {
  public isIndexOnly() {
    return /^[/]?economic-sector[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
