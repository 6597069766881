import { reactive } from 'vue';
import { Options } from 'vue-class-component';
import masks from 'magic-masks';

import config from '@/config/config';
import PageBaseView from '@/pages/PageBaseView';
import { validatePermission } from '@/helpers/module-permission';
import * as CompanyService from '@/services/company.service';
import * as ContactService from '@/services/contact.service';
import { ICompany } from '@/domain/company.model';
import { IContact } from '@/domain/contact.model';

@Options({
  name: 'company-view',
  props: {
    id: String,
  },
})
export default class CompanyView extends PageBaseView {
  public contacts: IContact[] = reactive([]);
  public row: ICompany = {
    cnpj: '',
    inscricao_estadual: '',
    inscricao_municipal: '',
    company: '',
    trade_name: '',
    street_name: '',
    complement: '',
    district: '',
    zip_code: '',
    city: '',
    state: '',
    description: '',
    segment_description: '',
    type_description: '',
    economic_sector_description: '',
    partner: false,
    enabled: false,
    finance_credit: false,
    type: 0,
    segment: 0,
    economic_sector: 0,
  };

  public created(): void {
    this.url = 'company';
    this.module = config.modules.company;

    CompanyService.get(this.id)
      .then((res: ICompany) => {
        if (res && res.id) {
          this.row = res;

          this.row.cnpj = masks.cnpj(this.row.cnpj);
          this.row.zip_code = masks.zipCode(this.row.zip_code);
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });

    ContactService.getByCompany(this.id)
      .then((res: IContact[]) => {
        this.contacts = res;

        this.contacts.forEach((i) => {
          i.phone1 = i.phone1 ? masks.cellphoneOrPhone(i.phone1) : '';
        });
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public hasPermissionContact(permission: string): boolean {
    return validatePermission(config.modules.contact, permission);
  }

  public newContact() {
    this.$router.push(`/contact/new/${this.id}`);
  }

  public viewContact(id: number) {
    this.$router.push(`/contact/${id}`);
  }

  public editContact(id: number) {
    this.$router.push(`/contact/${id}/e`);
  }
}
