import { Options } from 'vue-class-component';

import config from '@/config/config';
import PageBaseView from '@/pages/PageBaseView';
import * as CompanySegmentService from '@/services/company-segment.service';
import { ICompanySegment } from '@/domain/company-segment.model';

@Options({
  name: 'company-segment-view',
  props: {
    id: String,
  },
})
export default class CompanySegmentView extends PageBaseView {
  public row: ICompanySegment = {
    description: '',
    enabled: false,
  };

  public mounted(): void {
    this.url = 'company-segment';
    this.module = config.modules.companySegment;

    CompanySegmentService.get(this.id)
      .then((res: ICompanySegment) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }
}
