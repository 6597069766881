import * as SystemUser from '@/services/system-user.service';
import { useToast } from 'vue-toastification';

import {Options, Vue} from 'vue-class-component';

@Options({})
export default class ForgotPassword extends Vue {
  private appElement: HTMLElement | null = null;
  public sended: boolean = false;
  public username: string = '';
  private toast = useToast();

  public mounted(): void {
    this.appElement = document.getElementById('app') as HTMLElement;
    this.appElement.classList.add('login-page');
  }

  public unmounted(): void {
    (this.appElement as HTMLElement).classList.remove('login-page');
  }

  public forgotPassword(): void {
    SystemUser.forgotPassword(this.username)
      .then(() => this.sended = true)
      .catch((error) => {
        this.toast.error(error.response?.data?.error);
      });
  }
}
