import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';

import * as CityService from '@/services/city.service';

@Options({
  name: 'order-new',
  components: {
    Toggle,
  },
})
export default class OrderNew extends Vue {
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public row = {
    city: '',
    state: '',
    max_order_cars: '',
    enabled: true,
  };

  public goBack() {
    this.$router.push('/city');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    this.row.city = this.row.city?.trim();

    if (!this.row.city) {
      this.errors.city = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.state) {
      this.errors.state = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.max_order_cars) {
      this.errors.max_order_cars = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      CityService.insert({
        city: this.row.city,
        state: this.row.state,
        max_order_cars: parseInt(this.row.max_order_cars), 
        enabled: this.row.enabled,
      })
        .then(() => {
          this.$router.push('/city');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
