import config from '@/config/config';

import Index from '@/pages/company/index.vue';
import List from '@/pages/company/list/list.vue';
import View from '@/pages/company/view/view.vue';
import New from '@/pages/company/new/new.vue';
import Edit from '@/pages/company/edit/edit.vue';

const modulePermission = config.modules.company;

export default {
  path: '/company',
  name: 'company',
  component: Index,
  meta: {
    requiresAuth: true,
    modulePermission: modulePermission,
    requiresPermission: 'enabled_read',
  },
  children: [
    {
      path: 'list',
      name: 'company-list',
      component: List,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_read',
      },
    },
    {
      path: ':id',
      name: 'company-view',
      component: View,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_read',
      },
      props: true,
    },
    {
      path: 'new',
      name: 'company-new',
      component: New,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_create',
      },
    },
    {
      path: ':id/e',
      name: 'company-edit',
      component: Edit,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_edit',
      },
      props: true,
    },
  ],
};
