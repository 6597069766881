import store from '@/store/index';
import { LoginModule } from '@/domain/login.model';

export const validatePermission = (module: string, permission: string): boolean => {
  const modules = store.getters['auth/modules'] || [];

  const ret = modules.find((i: LoginModule) => i.module === module);

  return ret != null && ret[`enabled_${permission}`];
};
