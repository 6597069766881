import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';

import * as CarGroupService from '@/services/car-group.service';
import * as CityService from '@/services/city.service';
import { IList } from '@/domain/list.model';
import { ICity } from '@/domain/city.model';

@Options({
  name: 'car-group-new',
  components: {
    Toggle,
  },
})
export default class CarGroupNew extends Vue {
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public city: IList[] = reactive([]);
  public row = {
    id_city: 0,
    name: '',
    description: '',
    total_cars: '',
    city_description: '',
    enabled: true,
  };

  public created(): void {
    CityService.listActive()
      .then((res: ICity[]) => {
          this.city = res.map((i) => {
          return { id: i.id, description: i.city };
        });
      });
  }

  public goBack() {
    this.$router.push('/car-group');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    this.row.name = this.row.name?.trim();
    this.row.description = this.row.description?.trim();

    if (!this.row.id_city) {
      this.errors.id_city = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }


    if (!this.row.name) {
      this.errors.name = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.description) {
      this.errors.description = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.total_cars) {
      this.errors.total_cars = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      CarGroupService.insert({
        id_city: this.row.id_city,
        name: this.row.name,
        description: this.row.description,
        total_cars: parseInt(this.row.total_cars),
        enabled: this.row.enabled,
      })
        .then(() => {
          this.$router.push('/car-group');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
