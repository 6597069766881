import { Options, Vue } from 'vue-class-component';

import List from '@/pages/company/list/list.vue';

@Options({
  name: 'company',
  components: {
    'page-list': List,
  },
})
export default class Company extends Vue {
  public isIndexOnly() {
    return /^[/]?company[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
