import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';
import { isCnpj, isCep } from 'validator-brazil';
import cep from 'cep-promise';
import masks from 'magic-masks';

import { onlyNumbers } from '@/helpers/numbers';
import * as CompanyService from '@/services/company.service';
import { ICompany } from '@/domain/company.model';
import { ICompanyList } from '@/domain/company-list.model';
import { IList } from '@/domain/list.model';

@Options({
  name: 'company-edit',
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class CompanyEdit extends Vue {
  public id: string;
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public lastCEP = '';
  public inscricao_estadual_block = false;
  public inscricao_municipal_block = false;
  public companyTypes: IList[] = reactive([]);
  public companySegments: IList[] = reactive([]);
  public economicSectors: IList[] = reactive([]);
  public row: ICompany = {
    cnpj: '',
    inscricao_estadual: '',
    inscricao_municipal: '',
    company: '',
    trade_name: '',
    street_name: '',
    complement: '',
    district: '',
    zip_code: '',
    city: '',
    state: '',
    description: '',
    segment_description: '',
    type_description: '',
    economic_sector_description: '',
    partner: false,
    enabled: false,
    finance_credit: false,
    segment: 0,
    economic_sector: 0,
  };

  public created(): void {
    CompanyService.listsToForm()
      .then((res: ICompanyList) => {
        if (res && res.types) {
          this.companyTypes = res.types;
          this.companySegments = res.segments;
          this.economicSectors = res.economic_sectors;
        }
      });
  }

  public mounted(): void {
    CompanyService.get(this.id)
      .then((res: ICompany) => {
        if (res && res.id) {
          this.row = res;

          this.row.cnpj = masks.cnpj(this.row.cnpj);
          this.row.zip_code = masks.zipCode(this.row.zip_code);

          this.inscricao_estadual_block = this.row.inscricao_estadual != null && this.row.inscricao_estadual != '';
          this.inscricao_municipal_block = this.row.inscricao_municipal != null && this.row.inscricao_municipal != '';
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public goBack() {
    this.$router.push('/company');
  }

  public findCEP() {
    if (isCep(this.row.zip_code)) {
      if (this.lastCEP !== this.row.zip_code) {
        this.lastCEP = this.row.zip_code;

        cep(this.row.zip_code)
          .then((res: any) => {
            this.row.street_name = res.street;
            this.row.district = res.neighborhood;
            this.row.city = res.city;
            this.row.state = res.state;

            this.errors.zip_code = null;
          })
          .catch(() => {
            this.row.street_name = '';
            this.row.district = '';
            this.row.city = '';
            this.row.state = '';

            this.errors.zip_code = 'CEP não encontrado.';
          });
      }
    }
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.cnpj) {
      this.errors.cnpj = 'Este campo deve ser preenchido.';
      this.hasError = true;
    } else if (!isCnpj(this.row.cnpj)) {
      this.errors.cnpj = 'CNPJ inválido.';
      this.hasError = true;
    }

    if (!this.row.company) {
      this.errors.company = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.trade_name) {
      this.errors.trade_name = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.street_name) {
      this.errors.street_name = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.district) {
      this.errors.district = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.zip_code) {
      this.errors.zip_code = 'Este campo deve ser preenchido.';
      this.hasError = true;
    } else if (!isCep(this.row.zip_code)) {
      this.errors.zip_code = 'CEP inválido.';
      this.hasError = true;
    }

    if (!this.row.city) {
      this.errors.city = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.state) {
      this.errors.state = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.segment) {
      this.errors.segment = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.economic_sector) {
      this.errors.economic_sector = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      CompanyService.update({
        id: parseInt(this.id),
        inscricao_estadual: this.row.inscricao_estadual,
        inscricao_municipal: this.row.inscricao_municipal,
        company: this.row.company,
        trade_name: this.row.trade_name,
        street_name: this.row.street_name,
        complement: this.row.complement,
        district: this.row.district,
        zip_code: onlyNumbers(this.row.zip_code),
        city: this.row.city,
        state: this.row.state,
        description: this.row.description,
        segment: this.row.segment,
        economic_sector: this.row.economic_sector,
        partner: this.row.partner,
        enabled: this.row.enabled,
        finance_credit: this.row.finance_credit,
      })
        .then(() => {
          this.$router.push('/company');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
