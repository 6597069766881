import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';

import * as PriceListService from '@/services/price-list.service';
import * as ProductTypeService from '@/services/product-type.service';
import { IPriceList } from '@/domain/price-list.model';
import { IProductType } from '@/domain/product-type.model';
import { IList } from '@/domain/list.model';

@Options({
  name: 'price-list-edit',
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class PriceListEdit extends Vue {
  public id: string;
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public productTypes: IList[] = reactive([]);
  public row: IPriceList = {
    name: '',
    description: '',
    price: 0,
    product_type: 0,
    enabled: true,
  };

  public created(): void {
    ProductTypeService.listActive()
      .then((res: IProductType[]) => {
          this.productTypes = res.map((i) => {
          return { id: i.id, description: i.description };
        });
      });
  }

  public mounted(): void {
    PriceListService.get(this.id)
      .then((res: IPriceList) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public goBack() {
    this.$router.push('/price-list');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.name) {
      this.errors.name = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.price) {
      this.errors.price = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.product_type) {
      this.errors.product_type = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.description) {
      this.errors.description = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      PriceListService.update({
        id: parseInt(this.id),
        name: this.row.name,
        description: this.row.description,
        price: this.row.price,
        product_type: this.row.product_type,
        enabled: this.row.enabled,
      })
        .then(() => {
          this.$router.push('/price-list');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
