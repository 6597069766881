import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';

import * as TypeMediaService from '@/services/type-media.service';
import * as DocumentTypeService from '@/services/document-type.service';
import { IList } from '@/domain/list.model';
import { IDocumentType } from '@/domain/document-type.model';

@Options({
  name: 'type-media-new',
  components: {
    Toggle,
  },
})
export default class TypeMediaNew extends Vue {
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public documentTypes: IList[] = reactive([]);
  public row = {
    tag: '',
    description: '',
    time: '',
    max_file_size: '',
    max_file_size_type: 'MB',
    type_description: '',
    enabled: true,
    document_type: 0,
  };

  public created(): void {
    DocumentTypeService.listActive()
      .then((res: IDocumentType[]) => {
          this.documentTypes = res.map((i) => {
          return { id: i.id, description: i.description };
        });
      });
  }

  public goBack() {
    this.$router.push('/type-media');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.tag) {
      this.errors.tag = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.time) {
      this.errors.time = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.max_file_size) {
      this.errors.max_file_size = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.document_type) {
      this.errors.document_type = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.description) {
      this.errors.description = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      TypeMediaService.insert({
        tag: this.row.tag,
        description: this.row.description,
        time: parseInt(this.row.time),
        max_file_size: this.row.max_file_size + this.row.max_file_size_type,
        document_type: this.row.document_type,
        enabled: this.row.enabled,
      })
        .then(() => {
          this.$router.push('/type-media');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
