import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';

import * as CompanyTypeService from '@/services/company-type.service';

@Options({
  name: 'company-type-new',
  components: {
    Toggle,
  },
})
export default class CompanyTypeEdit extends Vue {
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public row = {
    description: '',
    enabled: true,
  };

  public goBack() {
    this.$router.push('/company-type');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.description) {
      this.errors.description = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      CompanyTypeService.insert({
        description: this.row.description,
        enabled: this.row.enabled,
      })
        .then(() => {
          this.$router.push('/company-type');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
