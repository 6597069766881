import axios from 'axios';

import config from '@/config/config';
import { headers } from './auth.headers';
import { formatQueryString } from '@/helpers/query-string';
import { IPriceList } from '@/domain/price-list.model';
import { IPriceListTypeMedia } from '@/domain/price-list-type-media.model';
import { IPaginateVueTable } from '@/interfaces/IPaginateVueTable';

const url_api_base = config.url_api_base;

export const insert = (data: IPriceList): Promise<IPriceList> => {
  return axios.post(`${url_api_base}/price-list`, data, headers());
};

export const update = (data: IPriceList): Promise<IPriceList> => {
  return axios.patch(`${url_api_base}/price-list/${data.id}`, data, headers());
};

export const get = (id: string): Promise<IPriceList> => {
  const url = `${url_api_base}/price-list/${id}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const list = (
  offset?: number,
  limit?: number,
  order?: string,
  sort?: string,
  filter?: string,
): Promise<IPaginateVueTable<IPriceList>> => {
  const query = formatQueryString(offset, limit, order, sort, filter);
  const url = `${url_api_base}/price-list${query}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const insertTypeMedia = (data: IPriceListTypeMedia): Promise<IPriceListTypeMedia> => {
  return axios.post(`${url_api_base}/price-list/${data.id_price_list}/type-media`, data, headers());
};

export const removerTypeMedia = (
  id_price_list: number,
  id_type_media: number,
): Promise<IPriceListTypeMedia> => {
  return axios.delete(`${url_api_base}/price-list/${id_price_list}/type-media/${id_type_media}`, headers());
};

export const getMediaTypes = (id_price_list: string): Promise<IPriceListTypeMedia[]> => {
  const url = `${url_api_base}/price-list/${id_price_list}/type-media`;

  return axios.get(url, headers()).then((res) => res?.data);
};
