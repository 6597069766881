import axios from 'axios';

import config from '@/config/config';
import { headers } from './auth.headers';
import { ISystemModuleProfile } from '@/domain/system-module-profile.model';

const url_api_base = config.url_api_user;

export const update = (id_access_profile: string, data: ISystemModuleProfile[]): Promise<ISystemModuleProfile> => {
  for (const module of data) {
    module.enabled_read = module.enabled_read === undefined ? false : module.enabled_read;
    module.enabled_create = module.enabled_create === undefined ? false : module.enabled_create;
    module.enabled_edit = module.enabled_edit === undefined ? false : module.enabled_edit;
    module.enabled_delete = module.enabled_delete === undefined ? false : module.enabled_delete;
  }

  return axios.patch(`${url_api_base}/system-access-profile/${id_access_profile}/module`, data, headers());
};

export const getModulesByProfileId = (id_access_profile: string): Promise<ISystemModuleProfile[]> => {
  const url = `${url_api_base}/system-access-profile/${id_access_profile}/module`;

  return axios.get(url, headers()).then((res) => res?.data);
};
