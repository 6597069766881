import config from '@/config/config';

import Index from '@/pages/price-list/index.vue';
import List from '@/pages/price-list/list/list.vue';
import View from '@/pages/price-list/view/view.vue';
import New from '@/pages/price-list/new/new.vue';
import Edit from '@/pages/price-list/edit/edit.vue';

const modulePermission = config.modules.priceList;

export default {
  path: '/price-list',
  name: 'price-list',
  component: Index,
  meta: {
    requiresAuth: true,
    modulePermission: modulePermission,
    requiresPermission: 'enabled_read',
  },
  children: [
    {
      path: 'list',
      name: 'price-list-list',
      component: List,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_read',
      },
    },
    {
      path: ':id',
      name: 'price-list-view',
      component: View,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_read',
      },
      props: true,
    },
    {
      path: 'new',
      name: 'price-list-new',
      component: New,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_create',
      },
    },
    {
      path: ':id/e',
      name: 'price-list-edit',
      component: Edit,
      meta: {
        modulePermission: modulePermission,
        requiresPermission: 'enabled_edit',
      },
      props: true,
    },
  ],
};
