import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';

import * as CityService from '@/services/city.service';
import { ICity } from '@/domain/city.model';

@Options({
  name: 'city-edit',
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class CityEdit extends Vue {
  public id: string;
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public row: ICity = {
    city: '',
    state: '',
    max_order_cars: 0,
    enabled: false,
  };

  public mounted(): void {
    CityService.get(this.id)
      .then((res: ICity) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public goBack() {
    this.$router.push('/city');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    this.row.city = this.row.city?.trim();

    if (!this.row.city) {
      this.errors.city = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.state) {
      this.errors.state = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.max_order_cars) {
      this.errors.max_order_cars = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      CityService.update({
        id: parseInt(this.id),
        city: this.row.city,
        state: this.row.state,
        max_order_cars: this.row.max_order_cars,
        enabled: this.row.enabled,
      })
        .then(() => {
          this.$router.push('/city');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
