import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';

import * as CompanySegmentService from '@/services/company-segment.service';
import { ICompanySegment } from '@/domain/company-segment.model';

@Options({
  name: 'company-segment-edit',
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class CompanySegmentEdit extends Vue {
  public id: string;
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public row: ICompanySegment = {
    description: '',
    enabled: false,
  };

  public mounted(): void {
    CompanySegmentService.get(this.id)
      .then((res: ICompanySegment) => {
        if (res && res.id) {
          this.row = res;
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public goBack() {
    this.$router.push('/company-segment');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.description) {
      this.errors.description = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      CompanySegmentService.update({
        id: parseInt(this.id),
        description: this.row.description,
        enabled: this.row.enabled,
      })
        .then(() => {
          this.$router.push('/company-segment');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
