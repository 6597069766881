import { Options, Vue } from 'vue-class-component';

import List from '@/pages/price-list/list/list.vue';

@Options({
  name: 'price-list',
  components: {
    'page-list': List,
  },
})
export default class PriceList extends Vue {
  public isIndexOnly() {
    return /^[/]?price-list[/]?$/.test(this.$router.currentRoute.value.path);
  }
}
