import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';
import { isCpf, isEmail } from 'node-simple-validator';
import masks from 'magic-masks';

import { onlyNumbers } from '@/helpers/numbers';
import * as SystemUserService from '@/services/system-user.service';
import * as SystemAccessProfile from '@/services/system-access-profile.service';
import { ISystemUser } from '@/domain/system-user.model';
import { ISystemAccessProfile } from '@/domain/system-access-profile.model';
import { IList } from '@/domain/list.model';

@Options({
  name: 'system-user-edit',
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class SystemUserEdit extends Vue {
  public id: string;
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public systemAccessProfiles: IList[] = reactive([]);
  public row: ISystemUser = {
    cpf: '',
    name: '',
    email: '',
    phone: '',
    username: '',
    enabled: false,
    id_access_profile: 0,
  };

  public created(): void {
    SystemAccessProfile.listActive()
      .then((res: ISystemAccessProfile[]) => {
        this.systemAccessProfiles = res.map((i) => {
          return { id: i.id, description: i.name };
        });
      });
  }

  public mounted(): void {
    SystemUserService.get(this.id)
      .then((res: ISystemUser) => {
        if (res && res.id) {
          this.row = res;

          this.row.cpf = this.row.cpf ? masks.cpf(this.row.cpf) : '';
          this.row.phone = this.row.phone ? masks.cellphoneOrPhone(this.row.phone) : '';
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public goBack() {
    this.$router.push('/system-user');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.name) {
      this.errors.name = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (this.row.cpf && !isCpf(this.row.cpf)) {
      this.errors.cpf = 'CPF inválido.';
      this.hasError = true;
    }

    if (!this.row.email) {
      this.errors.email = 'Este campo deve ser preenchido.';
      this.hasError = true;
    } else if (!isEmail(this.row.email)) {
      this.errors.email = 'E-mail inválido.';
      this.hasError = true;
    }

    if (!this.row.id_access_profile) {
      this.errors.id_access_profile = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      SystemUserService.update({
        id: parseInt(this.id),
        name: this.row.name,
        cpf: this.row.cpf ? onlyNumbers(this.row.cpf) : undefined,
        email: this.row.email,
        phone: this.row.phone ? onlyNumbers(this.row.phone) : undefined,
        enabled: this.row.enabled,
        id_access_profile: this.row.id_access_profile,
      })
        .then(() => {
          this.$router.push('/system-user');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
