import axios from 'axios';

import config from '@/config/config';
import { headers } from './auth.headers';
import { formatQueryString } from '@/helpers/query-string';
import { ICarGroup } from '@/domain/car-group.model';
import { IPaginateVueTable } from '@/interfaces/IPaginateVueTable';

const url_api_base = config.url_api_base;

export const insert = (data: ICarGroup): Promise<ICarGroup> => {
  return axios.post(`${url_api_base}/car-group`, data, headers());
};

export const update = (data: ICarGroup): Promise<ICarGroup> => {
  return axios.patch(`${url_api_base}/car-group/${data.id}`, data, headers());
};

export const get = (id: string): Promise<ICarGroup> => {
  const url = `${url_api_base}/car-group/${id}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const list = (
  offset?: number,
  limit?: number,
  order?: string,
  sort?: string,
  filter?: string,
): Promise<IPaginateVueTable<ICarGroup>> => {
  const query = formatQueryString(offset, limit, order, sort, filter);
  const url = `${url_api_base}/car-group${query}`;

  return axios.get(url, headers()).then((res) => res?.data);
};

export const listActive = (): Promise<ICarGroup[]> => {
  const url = `${url_api_base}/car-group/list-active`;

  return axios.get(url, headers()).then((res) => res?.data);
};
