import { Vue } from 'vue-class-component';

import { validatePermission } from '@/helpers/module-permission';

export default class PageBaseView extends Vue {
  public url = '';
  public module = '';
  public id: string;
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;

  public hasPermission(permission: string): boolean {
    return validatePermission(this.module, permission);
  }

  public goBack() {
    this.$router.push(`/${this.url}`);
  }

  public edit() {
    this.$router.push(`/${this.url}/${this.id}/e`);
  }
}
