import { calculateWindowSize } from '@/helpers/helpers';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class App extends Vue {
  get currentWindowSize() {
    if (this.$store.getters['ui/screenSize'] !== this.windowSize) {
      this.$store.dispatch('ui/setWindowSize', this.windowSize);
    }
    return this.windowSize;
  }

  get windowSize() {
    return calculateWindowSize(this.$windowWidth);
  }
}
