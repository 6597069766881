import { Options, Vue } from 'vue-class-component';
import Toggle from '@vueform/toggle';
import { isCpf, isEmail } from 'node-simple-validator';
import masks from 'magic-masks';

import { onlyNumbers } from '@/helpers/numbers';
import * as InsideSalesService from '@/services/inside-sales.service';
import { IInsideSales } from '@/domain/inside-sales.model';

@Options({
  name: 'inside-sales-edit',
  props: {
    id: String,
  },
  components: {
    Toggle,
  },
})
export default class InsideSalesEdit extends Vue {
  public id: string;
  public errorMessage = '';
  public errors: any = {};
  public hasError = false;
  public row: IInsideSales = {
    cpf: '',
    name: '',
    email: '',
    phone1: '',
    phone2: '',
    description: '',
    enabled: false,
  };

  public mounted(): void {
    InsideSalesService.get(this.id)
      .then((res: IInsideSales) => {
        if (res && res.id) {
          this.row = res;

          this.row.cpf = masks.cpf(this.row.cpf);
          this.row.phone1 = this.row.phone1 ? masks.cellphoneOrPhone(this.row.phone1) : '';
          this.row.phone2 = this.row.phone2 ? masks.cellphoneOrPhone(this.row.phone2) : '';
        } else {
          this.errorMessage = 'Registro não encontrado.';
        }
      })
      .catch(() => {
        this.errorMessage = 'Registro não encontrado.';
      });
  }

  public goBack() {
    this.$router.push('/inside-sales');
  }

  public validation() {
    this.errors = {};
    this.hasError = false;

    if (!this.row.name) {
      this.errors.name = 'Este campo deve ser preenchido.';
      this.hasError = true;
    }

    if (!this.row.cpf) {
      this.errors.cpf = 'Este campo deve ser preenchido.';
      this.hasError = true;
    } else if (!isCpf(this.row.cpf)) {
      this.errors.cpf = 'CPF inválido.';
      this.hasError = true;
    }

    if (!this.row.email) {
      this.errors.email = 'Este campo deve ser preenchido.';
      this.hasError = true;
    } else if (!isEmail(this.row.email)) {
      this.errors.email = 'E-mail inválido.';
      this.hasError = true;
    }

    return !this.hasError;
  }

  public save() {
    if (this.validation()) {
      InsideSalesService.update({
        id: parseInt(this.id),
        name: this.row.name,
        cpf: onlyNumbers(this.row.cpf),
        email: this.row.email,
        phone1: this.row.phone1 ? onlyNumbers(this.row.phone1) : undefined,
        phone2: this.row.phone2 ? onlyNumbers(this.row.phone2) : undefined,
        description: this.row.description,
        enabled: this.row.enabled,
      })
        .then(() => {
          this.$router.push('/inside-sales');
        })
        .catch(() => {
          this.errorMessage = 'Ocorreu um erro ao gravar o registro.';
        });
    }
  }
}
